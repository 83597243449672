.resourceBlock {
    border: 1px solid grey;
    border-radius: 8px;
    min-height: 125px;

    &:hover {
        border: 1px solid #111111;
        background-color: #f5f5f5;
        cursor: pointer;
        transition: 0.3s;
    }
}