#location {
	background-color: #ffffff;
	ul.locationDetails {
		text-decoration: none;
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding: 0;
			list-style-type: none;
			a {
				line-height: 26px;
			}
		}
	}
	#locationImage {
		img {
			width: 100%;
		}
	}

	#googleMap {
		overflow: 'hidden';
    	position: 'relative';
    	height: 100%;
		width: 100%;
	 	iframe {
      		height: 100%;
			width: 100%;
      		position: 'relative';
    	}
	}
}
