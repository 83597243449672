#schedule {
  background-color: #ffffff;
  .responsiveCal {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
