#hype {
	position: relative;
	//top: 0px;
	width: 100%;
	height: 100%;

	#videoContainer {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		height: 100vh;
		width: 100%;
		video {
			position: absolute;
    	top: 0;
    	bottom: 0;
    	right: 0;
    	left: 0;
    	margin: auto;
			-o-object-fit: cover;
			object-fit: cover;
  		width: 100%;
  		height: 100%;
	    z-index: -2;
	    background-size: cover;
	    overflow: hidden;
		}
	}
	#textContainer {
		height: 100%;
		max-width: 100vw;
		padding-left: 32px;
		z-index: 2;
		margin-top: -50vh;
		position: relative;
		padding-bottom: 50vh;
	}
}
