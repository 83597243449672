#mainCTA {
	background-color: #171717;
	@media (min-width: 600px) {
		background-color: #171717;
	}
}
#main {
	display: flex;
	align-items: center;
  	justify-content: center;
}
