.roundedImageOverlay {
    border-radius: 100%;
    display: flex;
    flex: 0 0 250px;
    height: 250px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 250px;
    img {
        height: 100%
    }
}